@use "src/styles/typography";

.event_datepart {
  width: 3.125rem;
}

.event_day {
  font-size: 1.25rem;
  font-weight: 700;
  line-height: 1.375rem;
}

.event_monthyear {
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1rem;
}
