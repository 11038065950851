.event_date {
  position: absolute;
  display: flex;
  align-items: center;
  gap: 0.25rem;
  left: 0.5rem;
  bottom: 0.5rem;
  background-color: white;
  z-index: 1;
  padding: 0.25rem 0.5rem;
  border-top-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
  text-align: center;
}
