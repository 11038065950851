@use "src/styles/buttons";
@use "src/styles/palette";
@use "src/styles/animations";

$border-radius: 0.25rem;

.add_button {
  @extend %reset-button;
  overflow: hidden;
  background: rgba(0, 0, 0, 0.6);
  border-radius: 0 $border-radius;
  color: palette.$white;
  padding: 10px 12px;
  display: flex;
  align-items: center;
  font-size: 14px;

  transition: all 0.3s linear;

  &_text {
    transition: all 0.3s linear;
    width: 0px;
    opacity: 0;
  }

  &:hover,
  &__loading {
    background: palette.grey("light");
    color: palette.$matte-black;

    .add_button_text {
      margin-left: 0.5rem;
      width: 60px;
      opacity: 1;
    }
  }
}

.loading_spinner {
  @include animations.spinning-loader(8px, 2px);
  display: block;
  width: 16px;

  // center the spinner
  &:after {
    margin: auto 10px;
  }
}
