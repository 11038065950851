@use "src/styles/palette";

.chip {
  padding: 0.25rem 0.625rem;
  border-radius: 0.25rem;
  background-color: palette.grey(base);
  color: black;
  transition:
    background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  display: inline-flex;
  align-items: center;
  column-gap: 0.2rem;
  border: none;
  font-size: 0.875rem;
  font-family: "AmsterdamSans", sans-serif;

  &.active {
    padding-right: 0.6rem;
    background-color: black;
    color: white;
  }

  &.spaced {
    margin-right: 0.5rem;
    margin-bottom: 0.5rem;
  }
}

.chip_grey {
  background-color: palette.grey(border);
}

.chip_primary {
  background-color: palette.brand(primary);
  color: palette.brand(primary, contrast);
}

.chip_secondary {
  background-color: palette.brand(secondary);
  color: palette.brand(secondary, contrast);
}

.chip_black {
  background-color: palette.brand("black");
  color: palette.brand("black", contrast);
}

.theme {
  background: var(--750-purple);
}
