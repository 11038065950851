$underline-height: 1px;

@mixin text-underline-target() {
  background-image: linear-gradient(currentColor, currentColor);

  background-size: 0% $underline-height;
  background-repeat: no-repeat;
  background-position: left bottom;
  transition: background-size 300ms ease;
}

@mixin text-underline-trigger() {
  background-size: 100% $underline-height;
}

@mixin gradient-border($gradient, $background: white) {
  border: 2px solid transparent;
  background:
    linear-gradient($background, $background) padding-box,
    $gradient border-box;
}
