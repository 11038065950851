@use "src/styles/typography";
@use "src/styles/palette";

.tags {
  display: flex;
  gap: 0.5rem;

  .tag {
    display: inline-flex;
    column-gap: 0.5rem;
    margin-top: 0.625rem;
    font-weight: 700;
  }
}
