@use "src/styles/typography";
@use "src/styles/palette";
@use "src/styles/layout";
@use "src/styles/mixins";

.card_body {
  margin-top: 1rem;
}

.card_title {
  @extend .header_5;
  display: flex;
  justify-content: space-between;

  h3 {
    margin: 0;
    font-size: 18px;
    @include mixins.text-underline-target();
  }

  svg {
    width: 1.25rem;
  }
}

.card_title_icon {
  margin-left: auto;
}

.card_top {
  @extend .caption_text;
  margin-bottom: 0.25rem;
}

.card_bottom {
  color: palette.grey(dark);
  margin-top: 0.25rem;
}

.card {
  text-decoration: none;

  img {
    transition: transform 225ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  }

  &:hover {
    img {
      transform: scale(1.1);
    }

    .card_title {
      span {
        @include mixins.text-underline-trigger();
      }
    }
  }

  &_small {
    @extend .card;

    display: flex;
    column-gap: 1rem;

    .image {
      width: 100px;
    }

    .card_body {
      flex: 1;
      margin-top: 0;
    }
  }

  &_medium,
  &_large,
  &_x-large {
    @media (max-width: layout.breakpoint("md")) {
      .image {
        width: 100% !important;
      }
    }
  }

  &_medium {
    @extend .card;

    .image {
      max-width: 100%;
      width: 385px;
    }
  }

  &_large {
    @extend .card;

    .image {
      max-width: 100%;
      width: 280px;
    }
  }

  &_x-large {
    @extend .card;

    .image {
      max-width: 100%;
      width: 440px;
    }
  }
}
