@use "src/styles/typography";
@use "src/styles/buttons";
@use "src/styles/palette";
@use "src/styles/animations";
@use "src/styles/mixins";

.card {
  cursor: pointer;
  color: initial;
  text-decoration: none;
  display: block;

  img {
    transition: transform 225ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  }

  &:hover {
    img {
      transform: scale(1.1);
    }

    .title span {
      @include mixins.text-underline-trigger();
    }
  }
}

.title {
  @extend .header_5;

  font-weight: 700;
  margin-top: 0.66rem;
  position: relative;

  span {
    @include mixins.text-underline-target();
  }
}

.info_rows {
  color: palette.$matte-black;
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  gap: 0.2rem;

  li {
    display: grid;
    grid-template-columns: auto 1fr;
  }
}

.info_row_icon {
  padding: 2px 4px 0 0;
}

.info_row_text {
  display: flex;
  flex-direction: column;

  .infor_row_subtext {
    font-size: 0.875rem;
  }
}

.info {
  @extend .paragraph;

  font-size: 1rem;
  color: palette.grey(support);
  display: flex;
  column-gap: 0.5rem;
  align-items: center;
  margin-top: 0.3rem;
  & > div {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.soldout {
  color: palette.error(base);
}
