@use "src/styles/typography";
@use "src/styles/buttons";
@use "src/styles/palette";
@use "src/styles/animations";

$transition: 225ms cubic-bezier(0, 0, 0.2, 1) 0ms;

.favourite {
  @extend %reset-button;

  border-radius: 0 0.25rem;
  color: palette.$white;
  padding: 0.625rem 0.75rem;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  transition: background-color $transition;

  svg {
    transition: transform $transition;
  }

  &:hover {
    svg {
      transform: scale(1.3);
    }
  }

  &:active {
    svg {
      transform: scale(1);
    }
  }

  &.is_active {
    svg {
      animation: pop 0.3s ease;
    }
  }
}

.default {
  background-color: rgba(0, 0, 0, 0.6);

  &.is_active {
    background-color: red;
  }
}

.ball {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: palette.$white;
  border-radius: 50%;
  opacity: 0;
  pointer-events: none;
}

.balls {
  display: flex;
  position: absolute;
  width: 0.625rem;
  height: 0.625rem;
}

.theme {
  background: var(--750-gradient-02);
  &.is_active {
    svg {
      animation: pop 0.3s ease;
    }

    .ball-1 {
      animation: flow-1 0.4s ease;
    }

    .ball-2 {
      animation: flow-3 0.4s ease;
    }

    .ball-3 {
      animation: flow-2 0.5s ease;
    }
  }
}

@keyframes pop {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.6);
  }
  100% {
    transform: scale(1.3);
  }
}

@keyframes flow-1 {
  0% {
    opacity: 1;
    transform: translate(0, 0) scale(1);
  }
  50% {
    opacity: 0.7;
    transform: translate(-0.625rem, -1rem) scale(0.4);
  }
  100% {
    opacity: 0;
    transform: translate(-1rem, -0.625rem) scale(0.1);
  }
}

@keyframes flow-2 {
  0% {
    opacity: 1;
    transform: translate(0, 0) scale(1);
  }
  50% {
    opacity: 0.7;
    transform: translate(0, -1rem) scale(0.6);
  }
  100% {
    opacity: 0;
    transform: translate(0, -0.625rem) scale(0.3);
  }
}

@keyframes flow-3 {
  0% {
    opacity: 1;
    transform: translate(0, 0) scale(1);
  }
  50% {
    opacity: 0.7;
    transform: translate(0.625rem, -1rem) scale(0.5);
  }
  100% {
    opacity: 0;
    transform: translate(1rem, -0.625rem) scale(0.2);
  }
}
