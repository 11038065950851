@use "src/styles/palette";

@keyframes skeleton-loading {
  0% {
    background-color: palette.grey("base");
  }

  100% {
    background-color: palette.grey("dark");
  }
}

.SkeletonCard {
  display: flex;
  flex-direction: column;
  gap: 0.66rem;
}

.image {
  animation: skeleton-loading 1s linear infinite alternate;
  width: 100%;
  padding-top: 64%;
  background-color: #e0e0e0;
  border-radius: 4px;
  border-radius: 0.25rem;
}

.lines {
  display: flex;
  flex-direction: column;
  gap: 0.33rem;

  >div {
    animation: skeleton-loading 1s linear infinite alternate;
    width: 80%;
    height: 16px;
    background-color: #e0e0e0;
  }

  >:nth-of-type(1) {
    width: 100%;
  }

  >:nth-of-type(2) {
    width: 90%;
  }
}