@use "src/styles/palette";

.icon {
  width: 1rem;
  height: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;

  & svg {
    width: 100%;
    height: 100%;
    color: currentColor;
  }

  &_primary {
    color: palette.brand(primary);
  }

  &_secondary {
    color: palette.brand(secondary);
  }

  &_black {
    color: palette.brand("black");
  }

  &_white {
    color: palette.$white;
  }
}
